<template>
  <div 
    v-if="model.activateTabs && !tabsCookie"
    class=""
    :style="`background-color: ${model.tabsBackgroundColor}`"
  >
    <div class="layout-container text-center pt-48 py-32 relative">
      <button
        class="bg-white rounded-full h-24 w-24 float-right flex items-center justify-center border border-transparent hover:border-grey400 animate-all absolute right-24 md:right-56 top-16 md:top-24"
        @click="tabsCookie = 'true'"
      >
        <img
          :src="'/heroicons/outline/x-mark.svg?url'"
          alt="close"
          class="h-18 w-18"
          height="18"
          width="18"
        >
      </button>
      <WysiwygWrapper
        v-if="model.tabContent"
        :style-config="{
          'wysiwyg wysiwyg--sm-text inline-block mb-16 md:w-1/2 mx-auto': true,
        }"
        :html="model.tabContent"
      />
      <div ref="switchContainer" class="relative flex bg-[#DADDE280] p-4 rounded-md w-fit mx-auto">
        <div ref="slider" class="absolute top-0 left-0 bg-white rounded-md transition-all duration-300 h-[calc(100%-8px)] mt-4" />

        <div
          ref="firstBtn"
          class="btn relative z-10 cursor-pointer px-24 !h-32 md:!h-48"
          :class="{ '': activeTab === model.firstTabName }"
          @click="setActiveTab(model.firstTabName)"
        >
          {{ model.firstTabName }}
        </div>

        <!-- Second Option -->
        <div
          ref="secondBtn"
          class="btn relative z-10 cursor-pointer px-24 !h-32 md:!h-48"
          :class="{ '': activeTab === model.secondTabName }"
          @click="setActiveTab(model.secondTabName)"
        >
          {{ model.secondTabName }}
        </div>
      </div>
    </div> 
  </div>

  <div 
    v-else-if="model.activateTabs"
    class="relative layout-container pt-12 grid place-items-end px-16"
  >
    <div class="relative w-full sm:w-auto min-h-64">
      <div
        class="w-auto flex rounded-md text-small-medium"
        :style="`background-color: ${model.tabsBackgroundColor}`"
      >
        <div ref="tabWidget" class="relative pr-32 rounded-md w-full">
          <div
            class="bg-white rounded-full h-24 w-24 flex items-center justify-center border border-transparent animate-all ml-16 z-40 absolute top-10 sm:top-14 right-12 cursor-pointer"
            @click.stop="open = !open"
          >
            <Transition name="fade" mode="out-in">
              <img
                v-if="!open"
                :src="'/heroicons/outline/chevron-down.svg?url'"
                alt="close"
                class="h-18 w-18"
                height="18"
                width="18"
              >
              <img
                v-else
                :src="'/heroicons/outline/x-mark.svg?url'"
                alt="close"
                class="h-18 w-18"
                height="18"
                width="18"
                @click.stop="open = false"
              >
            </Transition>
          </div>
          <div v-if="!open" class="selected flex justify-between w-full text-black items-center">
            <div class="p-12 sm:p-16 border-r border-grey400 text-xsmall-medium sm:text-small-medium min-w-1/2 flex-shrink-0">{{ model.tabWidgetTitle }}</div>
            <div
              v-if="model.firstTabName"
              class="p-16 flex items-center !h-40 sm:!h-48 w-full justify-between cursor-pointer"
              @click.stop="open = !open"
            >
              <div class="text-xsmall sm:text-small-regular">
                {{ activeTab === model.firstTabName ? model.firstTabName : model.secondTabName }}
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="w-full sm:w-[360px] absolute shadow-lg rounded-md p-16 sm:p-24 top-0 right-0 animate-all z-30"
              :style="`background-color: ${model.tabsBackgroundColor}`"
            >
              <div class="mb-6 text-xsmall-medium sm:text-sm-medium">{{ model.tabWidgetTitle }}</div>
              <div class="mb-16 text-xsmall sm:text-sm-regular">{{ model.tabWidgetText }}</div>
              <div ref="switchContainer" class="relative flex bg-[#DADDE280] p-4 rounded-md w-full">
                <div
                  class="absolute w-1/2 top-0 bg-white rounded-md transition-all duration-300 h-[calc(100%-8px)] mt-4"
                  :class="{ 'left-4': activeTab === model.firstTabName,
                            'left-[calc(50%-4px)]': activeTab === model.secondTabName
                  }"
                />
                <div
                  ref="firstBtn"
                  class="btn relative z-10 cursor-pointer px-24 !h-40 w-1/2"
                  :class="{ '': activeTab === model.firstTabName }"
                  @click.stop="setActiveTab(model.firstTabName)"
                >
                  {{ model.firstTabName }}
                </div>
                <div
                  ref="secondBtn"
                  class="btn relative z-10 cursor-pointer px-24 !h-40 w-1/2"
                  @click="setActiveTab(model.secondTabName)"
                >
                  {{ model.secondTabName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="activeTab === model.firstTabName"
    v-epi-edit="'MainContent'" 
    class="pt-24 sm:pt-56 full-width-content-area overflow-x-hidden"
  >
    <EpiserverContentArea :model="model.mainContent" :full-width="true" />
  </div>
  <div
    v-if="model.activateTabs && activeTab === model.secondTabName"
    v-epi-edit="'SecondMainContent'" 
    class="pt-24 sm:pt-56 full-width-content-area overflow-x-hidden"
  >
    <EpiserverContentArea :model="model.secondMainContent" :full-width="true" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { useCartStore } from '~/store/cart';
import { CartType } from '~/api-types';
import { useUiStore } from '~/store/ui';
import useDisableScroll from '~/composables/useDisableScroll';
import { useGlobalContentStore } from '~/store/globalContent';
import type { JsonLD } from 'nuxt-jsonld/dist/types/index.d';
import type { StartPage } from '~/content-types';
import useDetectOutsideClick from '~/composables/useDetectOutsideClick';

const globalContentStore = useGlobalContentStore();
const { disableScroll } = useDisableScroll();
const uiStore = useUiStore();
const cartStore = useCartStore();
const host = globalContentStore.host;
const company = globalContentStore.marketSettings.company;
const open = ref();
const slider = ref(null);
const firstBtn = ref(null);
const secondBtn = ref(null);

const tabWidget = ref();

useDetectOutsideClick(tabWidget, () => {
  if (open.value) {
    open.value = false;
  }
});

const props = defineProps<{
  model: StartPage;
}>();

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  'image': props.model.meta?.openGraphImage?.url.includes(host) ? `${props.model.meta?.openGraphImage?.url}` : `${host}${props.model.meta?.openGraphImage?.url}`,
  'url':  host,
  'logo': `${host}/logos/swm-logotype-inverted.svg`,
  'name': company.name,
  'description': 'Svenskt snus och tobak online från Swedish Match alla varumärken.',
  'address': {
    '@type': 'PostalAddress',
    'streetAddress': 'Sveavägen 44',
    'addressLocality': 'Stockholm',
    'addressCountry': 'SE',
    'addressRegion': 'Stockholm',
    'postalCode': '11885',
  },
  'vatID': company.vatRegistrationNumber,
} as JsonLD;

useJsonld(jsonLd);

const activeTab = ref(props.model.firstTabName);

onMounted(async() => {
  updateSliderPosition();

  if (process.client && localStorage) {
    const savedTab = localStorage.getItem('activeTab');
    activeTab.value = savedTab || props.model.firstTabName;
  }
  
  nextTick(async() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    let orderNumber = params.get('orderNo') as string;

    if (process.client && orderNumber) {
      try {
        await globalContentStore.fetchAntiForgeryToken();

        orderNumber = atob(orderNumber);
        var success = await cartStore.repurchaseOrderFromOrderNo(orderNumber, CartType.Standard);

        if (success) {
          uiStore.setShowCartMenu(true);
          disableScroll();

          window.dataLayer?.push({
            event: 'Cart',
            category: 'cart',
            action: 'view',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  });
});

const updateSliderPosition = () => {
  nextTick(() => {
    const activeBtn = activeTab.value === props.model.firstTabName ? firstBtn.value : secondBtn.value;
    if (activeBtn && slider.value) {
      slider.value.style.width = `${activeBtn.offsetWidth}px`;
      slider.value.style.left = `${activeBtn.offsetLeft}px`;
    }
  });
};

const setActiveTab = ((tabName: string) => {
  activeTab.value = tabName;
  localStorage.setItem('activeTab', tabName);

  $fetch('/api/user/interactions/setActiveTab', {
    method: 'POST',
    body: {
      schemaId: 'StartPageTabSelected',
      tabName,
    },
  });
});

const tabsCookie = useCookie('start-page-tabs', {
  maxAge: 60*60*24*7,
});

watch(activeTab, updateSliderPosition);
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
